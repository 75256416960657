/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 14, 2018 */



@font-face {
    font-family: 'chau_philomene_oneitalic';
    src: url('chauphilomeneone-italic-webfont.woff2') format('woff2'),
         url('chauphilomeneone-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'chau_philomene_oneregular';
    src: url('chauphilomeneone-regular-webfont.woff2') format('woff2'),
         url('chauphilomeneone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}